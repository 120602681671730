import React, { memo } from "react";
import Path from "../../../models/constants/Path";
import Check, { Screen } from "../../../models/types/Check";
import { useCheck } from "../../components/CheckContext/CheckContext";
import DialogBody from "../../components/Dialog/components/DialogBody/DialogBody";
import DialogControls from "../../components/Dialog/components/DialogControls/DialogControls";
import Dialog from "../../components/Dialog/Dialog";
import DatePicker from "../../components/Input/components/DatePicker/DatePicker";
import { useLocalized } from "../../components/LocalizedContext/LocalizedContext";
import Options from "../../components/Options/Options";

function parse(str: string) {
	try {
		const [date, month, year] = str.split("/").map((n) => parseInt(n));
		return new Date(year, month - 1, date);
	} catch {
		return new Date();
	}
}

const EarlyReturnScreen: Screen = (props) => {
	const { translate } = useLocalized();
	const { check, updateCheck } = useCheck();

	const onIncapacityEndDateChange = (newIncapacityEndDate: string) => {
		updateCheck({ newIncapacityEndDate });
	};

	const onWorkerAgreementChange = (workerDisagreement: boolean) => {
		updateCheck({ workerDisagreement });
	};

	const onConfirmClick = () => {
		const { history } = props;

		if (check.workerPhoneNumber) {
			if(check.hasCompanyWhitelisting){
                history.push(Path.EXCLUSION_CHECK);
            }else {
                history.push(Path.COMMENTS);
            }
		} else {
			history.push(Path.PATIENT_SIGNATURE);
		}
	};

	const canProceed = () => {
		if (!check.newIncapacityEndDate || check.workerDisagreement == undefined) {
			return false;
		}

		if (!check.incapacityEndDate) {
			return false;
		}
		
		if (new Date(parse(check.newIncapacityEndDate)) >= new Date(parse(check.incapacityEndDate)) 
			|| new Date(parse(check.newIncapacityEndDate)) < new Date(parse(check.incapacityStartDate))) {
			return false;
		}

		return true;
	};

	return (
		<Dialog>
			<h1>{translate("earlyWorkerReturn")}</h1>

			<DialogBody alignment="start">
				<DatePicker label="earlyReturnLabel" initialValue={check.newIncapacityEndDate} min={new Date(parse(check.incapacityStartDate))} max={new Date(new Date().setTime(Check.parseDate(check.incapacityEndDate!).getTime() - 24 * 60 * 60 * 1000))} onChange={onIncapacityEndDateChange} />

				<Options
					label="workerDisagreementLabel"
					onChange={onWorkerAgreementChange}
					selectedValue={check.workerDisagreement}
					options={[
						{ value: true, text: "yes" },
						{ value: false, text: "no" },
					]}
				/>
			</DialogBody>

			<DialogControls canProceedToNext={canProceed()} onNextClick={onConfirmClick} />
		</Dialog>
	);
};

export default memo(EarlyReturnScreen);
