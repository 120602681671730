import React, { memo, useEffect, useState } from 'react';
import Path from '../../../models/constants/Path';
import { Screen } from '../../../models/types/Check';
import { useCheck } from '../../components/CheckContext/CheckContext';
import DialogBody from '../../components/Dialog/components/DialogBody/DialogBody';
import DialogControls from '../../components/Dialog/components/DialogControls/DialogControls';
import Dialog from '../../components/Dialog/Dialog';
import TextArea from '../../components/Input/components/TextArea/TextArea';
import TimePicker from '../../components/Input/components/TimePicker/TimePicker';
import { useLocalized } from '../../components/LocalizedContext/LocalizedContext';
import './WorkAccidentInformationScreenStyle.scss';

const WorkAccidentInformationScreen: Screen = (props) => {
  const { translate } = useLocalized();
  const { check, updateCheck } = useCheck();

  const onAccidentTimeChange = (accidentTime: string) => {
    updateCheck({ accidentTime: accidentTime?.replace('24', '00') });
  };

  const onAccidentDescriptionChange = (accidentDescription: string) => {
    updateCheck({ accidentDescription });
  };

  const canProceed = () => {
    if (
      !check.accidentDate ||
      !check.accidentTime ||
      !check.accidentDescription
    ) {
      return false;
    }

    return /^([0-1]?[0-9]|2[0-4]):[0-5][0-9]$/g.test(check.accidentTime!);
  };

  const onNextClick = () => {
    const { history } = props;
    history.push(Path.INJURY_INFORMATION);
  };

  return (
    <Dialog>
      <h1>{translate('accidentDescription')}</h1>

      <DialogBody alignment='start'>
        <div className='at-date-container'>
          <p className='at-date-description'>
            {translate('accidentDateAlready')}
          </p>
          <p className='at-date-element'>{check?.accidentDate}.</p>
        </div>
        <TimePicker
          label='accidentTimeLabel'
          onChange={onAccidentTimeChange}
          value={check?.accidentTime}
          initialValue={check?.accidentTime || '24:00'}
        />
        <TextArea
          maxLength={110}
          label='accidentDescriptionLabel'
          placeholder='accidentDescription'
          onChange={onAccidentDescriptionChange}
          initialValue={check.accidentDescription}
        />
      </DialogBody>

      <DialogControls
        canProceedToNext={canProceed()}
        onNextClick={onNextClick}
      />
    </Dialog>
  );
};

export default memo(WorkAccidentInformationScreen);
